import { createRouter, createWebHistory } from 'vue-router';
import Index from '../views/index.vue';
import store from '../store';
import { toRaw } from 'vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index,
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue'),
    meta: { requestUnauth: true },
  },
  {
    path: '/registrazione',
    name: 'registrazione',
    component: () => import('../views/registrazione.vue'),
    meta: { requestUnauth: true },
  },
  {
    path: '/prenota',
    name: 'prenota',
    component: () => import('../views/prenota.vue'),
    meta: { requestOrg: true, requiresBookingOnline: true },
    // Storing query params for next uses
    beforeEnter: (to, from, next) => {
      store.dispatch('booking/updateQueryParams', to.query);
      next();
    },
  },
  {
    path: '/richiamata',
    name: 'richiamata',
    component: () => import('../views/richiamata.vue'),
    meta: { requestOrg: true, requiresCupTelefonico: true },
  },
  {
    path: '/referti',
    name: 'referti',
    component: () => import('../views/referti.vue'),
    meta: { requestAuth: true },
  },
  {
    path: '/verifica',
    name: 'verifica',
    component: () => import('../views/verifica.vue'),
    meta: { requestAuth: true },
  },
  {
    path: '/riepilogo',
    name: 'riepilogo',
    component: () => import('../views/riepilogo.vue'),
    meta: { requestAuth: true, requestBooking: true },
  },
  {
    path: '/riepilogo-richiamata',
    name: 'riepilogo-richiamata',
    component: () => import('../views/riepilogo-richiamata.vue'),
    meta: { requestAuth: true, requestBookingRichiamata: true },
  },
  {
    path: '/conferma',
    name: 'conferma',
    component: () => import('../views/conferma.vue'),
    meta: { requestAuth: true, requestBooking: true },
  },
  {
    path: '/conferma-richiamata',
    name: 'conferma-richiamata',
    component: () => import('../views/conferma-richiamata.vue'),
    meta: { requestAuth: true, requestBookingRichiamata: true },
  },
  {
    path: '/prenotazioni',
    name: 'prenotazioni',
    component: () => import('../views/prenotazioni.vue'),
    meta: { requestAuth: true },
  },
  {
    path: '/profilo',
    name: 'profilo',
    component: () => import('../views/profilo.vue'),
    meta: { requestAuth: true },
  },
  {
    path: '/recupera-password',
    name: 'recupera-password',
    component: () => import('../views/recupera-password.vue'),
    meta: { requestAuth: false },
  },
  {
    path: '/cambia-password/:changekey/:id',
    name: 'cambia-password',
    component: () => import('../views/cambia-password.vue'),
    meta: { requestAuth: false },
    props: true,
  },
  {
    path: '/attivazione/:code/:id',
    name: 'attivazione',
    component: () => import('../views/attivazione.vue'),
    meta: { requestAuth: false },
    props: true,
  },
  {
    path: '/scelta-organizzazione',
    name: 'scelta-organizzazione',
    component: () => import('../views/scelta-organizzazione.vue'),
  },
  {
    path: '/scelta-organizzazione/:id',
    name: 'scelta-organizzazione-id',
    component: () => import('../views/scelta-organizzazione.vue'),
    props: true,
  },
  {
    path: '/condizioni-generali',
    name: 'condizioni-generali',
    component: () => import('../views/condizioni-generali.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/privacy-policy.vue'),
  },
  {
    path: '/pagamento/:id',
    name: 'pagamento',
    component: () => import('../views/pagamento.vue'),
    meta: { requestAuth: true },
    props: true,
  },
  {
    path: '/esito-pagamento',
    name: 'esito-pagamento',
    component: () => import('../views/esito-pagamento.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
  },
  {
    path: '/dev-mode',
    name: 'dev-mode',
    component: () => import('../views/dev-mode.vue'),
    meta: { onlyDevMode: true },
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('../views/maintenance.vue'),
    meta: { onlyMaintenanceMode: true },
  },
  {
    path: '/booking-continue',
    name: 'booking-continue',
  },
  {
    path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
    component: () => import('../views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Force get setting if missing
  if (Object.keys(toRaw(store.getters['settings/get'])).length === 0) {
    await store.dispatch('settings/retrieve');
  }

  // Force get organizations are missing
  if (Object.keys(toRaw(store.getters['organizations/get'])).length === 0) {
    await store.dispatch('organizations/retrieve');
  }

  setTimeout(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  }, 0);

  if (to.path !== '/maintenance' && store.getters['settings/isMaintenanceMode'] && !sessionStorage.getItem('backofficeLogged')) {
    next('/maintenance');
  }
  else if (to.path !== '/dev-mode' && to.path !== '/maintenance' && store.getters['settings/isDevMode'] && !sessionStorage.getItem('backofficeLogged')) {
    next('/dev-mode');
  }
  else if (to.path === '/booking-continue') {
    if (store.getters['booking/getSlot'].id !== null) {
      next('/riepilogo');
    }
    else if (store.getters['recall/getSlot'].id !== null || !store.getters['recall/getNeedsSlot']) {
      next('/riepilogo-richiamata');
    }
    else {
      next('/');
    }
  }
  else {
    // Requires booking online fs activated
    if (to.meta.requiresBookingOnline && !store.getters['settings/hasBookingOnline']) {
      next('/');
    }
    // Requires cup telefonico fs activated
    else if (to.meta.requiresCupTelefonico && !store.getters['organizations/hasCupTelefonico']) {
      next('/');
    }
    // Needs to be logged
    else if (to.meta.requestAuth && !store.getters['auth/isLogged']) {
      next('/login?r='+to.name);
    }
    // Needs to be unlogged
    else if (to.meta.requestUnauth && store.getters['auth/isLogged']) {
      next('/');
    }
    // Needs to have a selected booking
    else if (to.meta.requestBooking && !store.getters['booking/getSlot'].id) {
      next('/prenota');
    }
    // Needs to have a selected booking recall
    else if (to.meta.requestBookingRichiamata && (!store.getters['recall/getSlot'].id && store.getters['recall/getNeedsSlot'])) {
      next('/richiamata');
    }
    // Needs to have a selected organization
    else if (to.meta.requestOrg && store.getters['settings/get'] !== undefined && store.getters['settings/get'] !== null && store.getters['settings/get'].start_with_organization && !store.getters['app/get'].orgId) {
      next('/scelta-organizzazione');
    }
    // Only if in maintenance mode
    else if (to.meta.onlyMaintenanceMode && !store.getters['settings/isMaintenanceMode']) {
      next('/');
    }
    // Only if in dev mode
    else if (to.meta.onlyDevMode && !store.getters['settings/isDevMode']) {
      next('/');
    }
    else {
      next();
    }
  }
})

export default router;
