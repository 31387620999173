import apiAxios from '../../libs/apiAxios'

export default {
  namespaced: true,

  // Data
  state: {
    data: {
      /*
      direct: [],
      indirect: [],
      */
      all: [],
    }
  },

  // Get data
  getters: {
    /*
    getDirect(state) {
      return state.data.direct;
    },

    getIndirect(state) {
      return state.data.indirect;
    },
    */

    getInsurances(state) {
      return state.data.all;
    },

    /*
    getDirectInsuranceById: (state) => (id) => {
      if (state.data.direct.length === 0) {
        return {};
      }
      id = typeof id === 'string' ? parseInt(id) : id;
      return state.data.direct.find(insurance => insurance.id === id);
    },

    getIndirectInsuranceById: (state) => (id) => {
      if (state.data.indirect.length === 0) {
        return {};
      }
      id = typeof id === 'string' ? parseInt(id) : id;
      return state.data.indirect.find(insurance => insurance.id === id);
    },
    */

    getInsuranceById: (state) => (id) => {
      if (state.data.all.length === 0) {
        return {};
      }
      id = typeof id === 'string' ? parseInt(id) : id;
      return state.data.all.find(insurance => insurance.id === id);
    },

    getInsurancesByLabel: (state) => (label) => {
      if (state.data.all.length === 0 || label === '') {
        return [];
      }
      label = typeof label === 'string' ? String(label) : label;
      return state.data.all.filter(insurance => insurance.label.toLowerCase().includes(label.toLowerCase()));
    },
  },

  // Change data synchronously
  mutations: {
    /*
    setDirect(state, payload) {
        state.data.direct = payload;
    },

    setIndirect(state, payload) {
        state.data.indirect = payload;
    },
    */

    setInsurances(state, payload) {
      state.data.all = payload;
  },

    init(state) {
      /*
      state.data.direct = [];
      state.data.indirect = [];
      */
      state.data.all = [];
    }
  },

  // Async methods
  actions: {
    async retrieve(context, payload) {
      let queryString = '';

      // Organization id from app store
      const orgId = context.rootGetters['app/get'].orgId;
      if (orgId !== null) {
        // queryString = '&orgId=' + orgId;
        queryString = '?orgId=' + orgId;
      }

      if (payload.label !== undefined && payload.label !== null && payload.label !== '') {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'label=' + payload.label;
      }

      let response;

      /*
      response = await apiAxios.get('/insurances?type=direct'+queryString);
      context.commit('setDirect', response.data);

      response = await apiAxios.get('/insurances?type=indirect'+queryString);
      context.commit('setIndirect', response.data);
      */

      response = await apiAxios.get('/insurances'+queryString);
      context.commit('setInsurances', response.data);
    }
  }
};
