<template>
  <div class="bg-white min-vh-100">
    <DlHeader :showLogo="false" />

    <div class="container mt-5">
      <DlLogo :class="'a-dl-logo--lg mx-auto'" :logo="logo"/>

      <p class="fs-5 py-5 text-center">
        <strong>Accedi ai nostri servizi online direttamente dal tuo computer o smartphone</strong>
      </p>

      <DlNavbar />

      <DlIntro />

      <DlModal :show="confirmNext" @closeModal="closeModalNext()" @dismissModal="closeModalNext()" @confirmModal="goNext()" title="Stai prenotando un appuntamento telefonico con un operatore" text="Il portale permette la prenotazione di una richiamata telefonica da parte dell'operatore dell'ospedale. All'ora e al giorno scelto, verrai richiamato per concordare la data definitiva della visita secondo quanto indicato nel processo di prenotazione." confirmBtnText="CONTINUA" dismissBtnText="ANNULLA" />
    </div>

    <DlFooter />
  </div>
</template>

<script>
import DlFooter from '@/components/organisms/dl-footer/dl-footer.vue';
import DlHeader from '@/components/organisms/dl-header/dl-header.vue';
import DlIntro from '@/components/organisms/dl-intro/dl-intro.vue';
import DlLogo from '@/components/atoms/dl-logo/dl-logo.vue';
import DlNavbar from '@/components/molecules/dl-navbar/dl-navbar.vue';
import DlModal from '@/components/organisms/dl-modal/dl-modal.vue';
import cookies from "vue-cookies";

export default {
  name: 'Index',
  components: {
    DlModal,
    DlFooter,
    DlHeader,
    DlIntro,
    DlLogo,
    DlNavbar,
  },

  data() {
    return {
      confirmNext: false,
      goNextFunction: null,
    }
  },

  computed: {
    logo() {
      return this.$store.getters['settings/get'].logo;
    },
  },

  beforeRouteLeave(to, from, next) {
    // Remove orgId that was set as infinite
    // https://doctolib.atlassian.net/browse/APPO-1726
    // It can be removed in future
    cookies.remove('orgId');

    if (to.path === '/richiamata') {
      this.confirmNext = true;
      this.goNextFunction = next;
    }
    else {
      next();
    }
  },

  methods: {
    closeModalNext() {
      this.confirmNext = false;
    },

    goNext() {
      this.goNextFunction();
    },
  }
};
</script>

<style scoped>
.formatted-p > p:after {
  content: "\00a0";
}

:deep() .formatted-p > p:not(:last-child) {
  margin-bottom: 0.7em;
}
</style>
