<template>
    <component
      :is="type === 'link' ? 'router-link' : 'a'"
      class="a-dl-buttonnav"
      :to="href"
      :href="href"
      :title="title"
      :target="target">
      
        <DlIcon v-if="icon" :icon="icon" size="fs-1" :class="'a-dl-buttonnav__icon'" />
        
        <span class="a-AppoButton__label mt-2 text-center">
          <strong>{{label}}</strong>
          <small v-if="subLabel">{{subLabel}}</small>
        </span>
    </component>
</template>

<script>
import DlIcon from '@/components/atoms/dl-icon/dl-icon.vue';

export default {
  name: 'DlButtonNav',
  components: {
    DlIcon,
  },
  props: {
    href: {
      default: '#',
      type: String,
    },
    icon: {
      default: null,
      type: String,
    },
    label: {
      default: 'Continua',
      type: String,
    },
    size: {
      default: null,
      type: String,
    },
    subLabel: {
      default: null,
      type: String,
    },
    theme: {
      default: 'primary',
      type: String,
    },
    title: {
      default: '',
      type: String
    },
    type: {
      default: 'button',
      type: String,
    },
    target: {
      default: 'false',
      type: String,
    },    
  },
  computed: {
    getTheme(){
      switch (this.theme) {
        case 'primary':
        default: 
          return '';

        case 'secondary':
          return '';
      }
    }
  }
};
</script>


<style lang="scss">
  @import 'dl-buttonnav.scss';
</style>