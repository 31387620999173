import apiAxios from '../../libs/apiAxios'

export default {
  namespaced: true,

  // Data
  state: {
    data: []
  },

  // Get data
  getters: {
    get(state) {
      return state.data;
    },
    getDoctorById: (state) => (id) => {
      if (state.data.length === 0) {
        return {};
      }
      id = typeof id === 'string' ? parseInt(id) : id;
      return state.data.find(doctor => doctor.id === id);
    },
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {
        state.data = payload;
    },

    init(state) {
      state.data = [];
    }
  },

  // Async methods
  actions: {
    async retrieve(context, payload) {
      let queryString = '';

      // Organization id from app store
      const orgId = context.rootGetters['app/get'].orgId;
      if (orgId !== null) {
        queryString = '&orgId=' + orgId;
      }

      let response = await apiAxios.get('/doctors?insuranceId='+payload.insurance+queryString, {});

      context.commit('set', response.data);
    },
    async retrieveSingleDoctor(context, doctorId) {
      let response = null;
      // Check if we have an id of doctor
      if (doctorId) {
         let doctorIdx = null;
         // We get the actual list of doctors, for later update
         let doctors = context.state.data

        // Check if we have the requested doctor (by id) in the actual list
         if (doctors) {
           doctorIdx = doctors.findIndex(doctorObj => doctorObj.id === doctorId);
         }
         // Try to get the requested doctor id from the server, with the CV
         response = await apiAxios.get(`/doctors/${doctorId}`, {});

         // Check if we found the doctor in our list & the response of the server to have a CV
         // and update data in the list
         if (doctorIdx !== -1 && response.data.doctor_cv) {
           doctors[doctorIdx] = response.data
           context.commit('set', doctors)
         }
      }
      return response?.data
    }
  }
};
