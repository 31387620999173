<template>
    <img :src="src" class="d-block" :alt="alt">
</template>

<script>
export default {
  name: 'DlImageBase64',
  props: {
    alt: {
      default: 'Logo',
      type: String,
    },
    src: {
      default: 'logo-doctolib-hos.png',
      type: String,
    },
  },
};
</script>