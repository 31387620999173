<template>
    <component
      :is="type === 'link' ? 'router-link' : 'button'"
      :class="[
        'a-dl-button',
        clean ? 'a-dl-button--clean' : getTheme,
        disabled ? 'a-dl-button--disabled' : '',
        getSize,
        status
      ]"
      :to="href"
      :title="title">

        <DlIcon v-if="icon" :icon="icon" :class="'a-dl-button__icon me-2'" />

        <span :class="[
          'a-dl-button__label',
          icon ? 'text-left' : ''
        ]">
          <strong>{{label}} <slot></slot></strong>
          <small v-if="subLabel">{{subLabel}}</small>
        </span>



    </component>
</template>

<script>
import DlIcon from '@/components/atoms/dl-icon/dl-icon.vue';

export default {
  name: 'DlButton',
  components: {
    DlIcon,
  },
  props: {
    clean: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    href: {
      default: '',
      type: String,
    },
    icon: {
      default: null,
      type: String,
    },
    label: {
      default: 'Continua',
      type: String,
    },
    size: {
      default: null,
      type: String,
    },
    status: {
      default: null,
      type: String,
    },
    subLabel: {
      default: null,
      type: String,
    },
    theme: {
      default: 'primary',
      type: String,
    },
    title: {
      default: '',
      type: String
    },
    type: {
      default: 'button',
      type: String,
    },
  },
  computed: {
    getTheme(){
      switch (this.theme) {
        case 'primary':
        default:
          return 'btn btn-primary';

        case 'primary-outline':
          return 'btn btn-outline-primary';

        case 'secondary':
          return 'btn btn-secondary';

        case 'secondary-outline':
          return 'btn btn-outline-secondary';

        case 'primary-light':
          return 'btn btn-primary-light';

        case 'primary-light-outline':
          return 'btn btn-outline-primary-light';

        case 'success-light':
          return 'btn btn-success-light';

        case 'nav-link':
          return 'a-dl-button--navtab nav-link';

        case 'day-disabled':
          return 'a-dl-button--day-disabled';
      }
    },
    getSize(){
      switch (this.size) {
        default:
          return '';

        case 'small':
          return 'btn-sm';

        case 'large':
          return 'btn-lg';
      }
    }
  }
};
</script>


<style lang="scss">
  @import 'dl-button.scss';
</style>
