<template>
    <footer class="o-dl-footer py-5 text-muted container">
      <div class="row">
        <div class="col-12 col-md-6 o-dl-footer__left">
          <p class="d-flex align-items-center justify-content-center justify-content-md-end">
            {{ groupName }} - Powered by
            <img v-bind:src="footerLogo ?? require(`@/assets/images/logo-doctolib-blue.svg`)" class="footer-logo mx-1">
          </p>
        </div>
        <div class="col-12 col-md-6 o-dl-footer__right">
          <p><a class="text-muted" href="privacy-policy" target="_blank">Privacy policy</a> - <a class="text-muted" href="condizioni-generali" target="_blank">Termini e condizioni di servizio</a></p>
        </div>
      </div>
    </footer>
</template>

<script>
import DlImage from '@/components/atoms/dl-imagebase64/dl-imagebase64.vue';

export default {
  name: 'DlFooter',
  components: {
    DlImage,
  },
  computed: {
    groupName() {
      return this.$store.getters['settings/get'].group_name;
    },
    footerLogo() {
      return this.$store.getters['settings/get'].footer_logo;
    },
  }
};
</script>

<style lang="scss">
  @import 'dl-footer.scss';
</style>
