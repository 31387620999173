import apiAxios from '../../libs/apiAxios'
import router from '../../router/index'
import VueJwtDecode from 'vue-jwt-decode'
import cookies from "vue-cookies";

export default {
  namespaced: true,

  // Data
  state: {
    logged: false,
    userData: {
      access_token: null,
      refresh_token: null
    },
  },

  // Get data
  getters: {
    isLogged(state) {
      return state.logged;
    },

    loggedUser(state) {
      if (state.userData.access_token) {
        const user = VueJwtDecode.decode(state.userData.access_token);
        return (user.name+' '+user.surname).toUpperCase();
      }
      else
        return '';
    },

    loggedUserData(state) {
      if (state.userData.access_token) {
        return VueJwtDecode.decode(state.userData.access_token);
      }
      else
        return '';
    },

    loggedUserTokens(state) {
      if (state.userData) {
        return state.userData;
      }
      else
        return '';
    },

    isSpidUser(state) {
      return VueJwtDecode.decode(state.userData.access_token).spid_user;
    }
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {
      if(payload.data == 'logged') {
        state.logged = payload.value;
      }
      else if(payload.data == 'userData') {
        state.userData = payload.value;
      }
    },

    // Logout
    logout(state) {
      const isSpidUser = VueJwtDecode.decode(state.userData.access_token).spid_user;

      state.logged = false;
      state.userData = {
        access_token: null,
        refresh_token: null,
      };

      sessionStorage.removeItem('logged');
      sessionStorage.removeItem('userData');

      // SPID logout
      if (isSpidUser) {
        window.location.href = process.env.VUE_APP_DOMAIN + 'proxy?action=logout&client_id=' + process.env.VUE_APP_SPID_CLIENT_ID;
      }
      // Refresh current page
      else {
        router.go();
      }
    },

    // Auto login from local storage
    autoLogin(state) {
      const logged = sessionStorage.getItem('logged');
      const userData = sessionStorage.getItem('userData');

      if (logged && userData) {
        state.logged = true;
        state.userData = JSON.parse(userData);
      }
    },

    // Login w SPID cookie after redirect
    spidRedirectLogin(state) {
      let spid;
      if (cookies.isKey('spid')) {
        spid = cookies.get('spid');
        cookies.remove('spid');
      } else {
        spid = null;
      }

      if (spid) {
        state.logged = true;
        state.userData = spid;

        sessionStorage.setItem('logged', true);
        sessionStorage.setItem('userData', JSON.stringify(state.userData));
      }
    },
  },

  // Async methods
  actions: {
    async login(context, payload) {

      // Group id from settings store
      const groupId = context.rootGetters['settings/get'].id;

      const user = {
        fiscal_code: payload.username,
        password: payload.password,
        group_id: groupId
      }

      try {
        let response = await apiAxios.post(process.env.VUE_APP_SSO_ENDPOINT+'login', user);
        //if(response.status === 200) {
          context.commit('set', {data: 'logged', value: true});
          context.commit('set', {data: 'userData', value: response.data});

          sessionStorage.setItem('logged', true);
          sessionStorage.setItem('userData', JSON.stringify(response.data));

          return {success: true};
        //}
      } catch(e) {
        let msg;
        try {
          msg = e.response.data.message;
        } catch(e) {
          msg = 'Si è verificato un errore imprevisto, riprovare più tardi';
        }

        return {success: false, errorMessage: msg};
      }
    },

    async refreshToken(context, payload) {
      const body = {
        refresh_token: payload
      }

      try {
        let response = await apiAxios.post(process.env.VUE_APP_SSO_ENDPOINT+'refresh-token', body);

        //if(response.status === 200) {
          context.commit('set', {data: 'userData', value: response.data});

          sessionStorage.setItem('userData', JSON.stringify(response.data));

          return {success: true};
        //}
      } catch(e) {
        return {success: false, errorMessage: e.response.data.message};
      }
    },

    async updateUserContacts(context, payload) {
      const params = {
        phone: payload.phone,
        email: payload.email,
      }

      try {
        const response = await apiAxios.put(process.env.VUE_APP_SSO_ENDPOINT+'user/'+payload.id, params);

        return { success: true };
      } catch (e) {
        return { success: false, errorMessage: e.response.data.message };
      }
    },

    async updateUserPassword(context, payload) {
      const params = {
        password_old: payload.passwordOld,
        password: payload.password
      }

      try {
        const response = await apiAxios.put(process.env.VUE_APP_SSO_ENDPOINT+'password-change/'+payload.id, params);

        return {success: true};
      } catch(e) {
        return {success: false, errorMessage: e.response.data.message};
      }
    },

    async deleteUser(context, payload) {
      try {
        const response = await apiAxios.delete(process.env.VUE_APP_SSO_ENDPOINT+'user/'+payload.id+'/'+payload.password);

        return {success: true};
      } catch(e) {
        return {success: false, errorMessage: e.response.data.message};
      }
    },

    async validateUser(context, payload) {
      try {
        const response = await apiAxios.get(process.env.VUE_APP_SSO_ENDPOINT+'validate/'+payload.code+'/'+payload.id);

        return {success: true};
      } catch(e) {
        return {success: false, errorMessage: e.response.data.message};
      }
    },

    async validateUserByToken(context, payload) {
      try {
        const response = await apiAxios.get(process.env.VUE_APP_SSO_ENDPOINT+'validate-by-token/'+payload.token+'/'+payload.id);

        return {success: true};
      } catch(e) {
        return {success: false, errorMessage: e.response.data.message};
      }
    },

    async passwordReset(context, payload) {
      // Group id from settings store
      const groupId = context.rootGetters['settings/get'].id;

      const body = {
        fiscal_code: payload.username,
        group_id: groupId
      }

      try {
        const response = await apiAxios.put(process.env.VUE_APP_SSO_ENDPOINT+'password-reset', body);

        return {success: true, email: response.data.email};
      } catch(e) {
        return {success: false, errorMessage: e.response.data.message};
      }
    },

    async passwordResetChange(context, payload) {
      // Group id from settings store
      const groupId = context.rootGetters['settings/get'].id;

      const body = {
        id: payload.id,
        key: payload.key,
        password: payload.password,
        group_id: groupId
      }

      try {
        const response = await apiAxios.put(process.env.VUE_APP_SSO_ENDPOINT+'password-reset-change', body);

        return {success: true};
      } catch(e) {
        return {success: false, errorMessage: e.response.data.message};
      }
    },

    async updateUserResidence(context, payload) {
      const params = {
        town: payload.town,
        cap: payload.cap,
        address: payload.address,
        address_number: payload.address_number
      }

      try {
        const response = await apiAxios.put(process.env.VUE_APP_SSO_ENDPOINT+'user-residence/'+payload.id, params);

        return {success: true};
      } catch(e) {
        return {success: false, errorMessage: e.response.data.message};
      }
    },
  }
};
