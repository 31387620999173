import apiAxios from '../../libs/apiAxios'

export default { 
  namespaced: true,

  // Data
  state: {
    data: {}
  },

  // Get data
  getters: {
    get(state) {
      return state.data;
    }
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {      
        state.data = payload;
    },

    init(state) {
      state.data = {};
    }
  },

  // Async methods
  actions: {
    async retrieve(context, data) {
      //let response = await apiAxios.get('http://127.0.0.1:1234/prescription/'+data.cf+'/'+data.nre, {});

      let exams = await apiAxios.get('/exams');

      let response = {
        success: true,
        message: "",
        nre: "",
        cf:"",
        date: "",
        bookableExams: [exams.data[0]],
        unbookableExams: []
      };

      context.commit('set', response/*.data*/);
    }    
  }
};