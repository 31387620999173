<template>
    <div class="a-dl-loading d-flex">
        <div class="spinner-border text-primary" role="status" v-if="!getLoadingError"></div>
        <span class="ms-3">{{ getLoadingMessage }}</span>
    </div>
</template>

<script>
export default {
  name: 'DlLoading',
  computed: {
    getLoadingMessage() {
      return this.$store.getters['app/get'].loadingMessage
    },
    getLoadingError() {
      return this.$store.getters['app/get'].loadingError
    }    
  }
};
</script>

<style lang="scss">
  @import 'dl-loading.scss';
</style>