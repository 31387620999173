import apiAxios from '../../libs/apiAxios'

export default {
  namespaced: true,

  // Data
  state: {
    data: []
  },

  // Get data
  getters: {
    get(state) {
      return state.data;
    },

    hasCupTelefonico(state) {
      let ret = false;

      state.data.forEach((org) => {
        if (org.cup_telefonico === true) {
          ret = true;
        }
      });

      return ret;
    },
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {
        state.data = payload;
    },

    init(state) {
      state.data = [];
    }
  },

  // Async methods
  actions: {
    async retrieve(context, payload) {
      // Setting id from settings store
      const settingId = context.rootGetters['settings/get'].id;

      const response = await apiAxios.get('organizations?settingId='+settingId, {});
      context.commit('set', response.data);
    }
  }
};
