import apiAxios from '../../libs/apiAxios'

export default { 
  namespaced: true,

  // Data
  state: {
    nations: [],
    regions: [],
    provinces: [],
    towns: []
  },

  // Get data
  getters: {
    getNations(state) {
      return state.nations;
    },
    getRegions(state) {
      return state.regions;
    },
    getProvinces(state) {
      return state.provinces;
    },
    getTowns(state) {
      return state.towns;
    },    
  },

  // Change data synchronously
  mutations: {
    setNations(state, payload) {
      state.nations = payload;
    },
    setRegions(state, payload) {
      state.regions = payload;
    },
    setProvinces(state, payload) {
      state.provinces = payload;
    },
    setTowns(state, payload) {
      state.towns = payload;
    },

    initNations(state) {
      state.nations = [];
    },
    initRegions(state) {
      state.regions = [];
    },
    initProvinces(state) {
      state.provinces = [];
    },
    initTowns(state
      ) {
      state.towns = [];
    },    
  },

  // Async methods
  actions: {
    async retrieveNations(context) {
      const response = await apiAxios.get(process.env.VUE_APP_PLACE_ENDPOINT+'countries');

      // Remap values
      const data = response.data.map(item => {
        const res = {};
        res.value =  item.catastal_code;
        res.label =  item.comune;
        return res;
      });

      context.commit('setNations', data);
    },

    async retrieveRegions(context, payload) {
      let queryString = '';

      if (payload.nation !== undefined && payload.nation !== null && payload.nation !== 0) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'country=' + payload.nation;
      }  

      const response = await apiAxios.get(process.env.VUE_APP_PLACE_ENDPOINT+'regions'+queryString);

      // Remap values
      const data = response.data.map(item => {
        const res = {};
        res.value =  item.id;
        res.label =  item.region_name;
        return res;
      });

      context.commit('setRegions', data);
    },

    async retrieveProvinces(context, payload) {
      let queryString = '';

      if (payload.region !== undefined && payload.region !== null && payload.region !== 0) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'region=' + payload.region;
      }  

      const response = await apiAxios.get(process.env.VUE_APP_PLACE_ENDPOINT+'provinces'+queryString);
      
      // Remap values
      const data = response.data.map(item => {
        const res = {};
        res.value =  item.acronym;
        res.label =  item.name;
        return res;
      });

      context.commit('setProvinces', data);
    },    

    async retrieveTowns(context, payload) {
      let queryString = '';

      const currentDate = new Date().toJSON().slice(0, 10);

      if (payload.province !== undefined && payload.province !== null && payload.province !== 0) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'province=' + payload.province + '&validDate=' + currentDate;
      }  

      const response = await apiAxios.get(process.env.VUE_APP_PLACE_ENDPOINT+'towns'+queryString);

      // Remap values
      const data = response.data.map(item => {
        const res = {};
        res.value =  item.catastalCode;
        res.label =  item.name;
        return res;
      });      
     
      context.commit('setTowns', data);
    },
  }
};