import apiAxios from '../../libs/apiAxios'

export default { 
  namespaced: true,

  // Data
  state: {
    data: {
      name: {
        value: '',
        valid: null,
      },
      surname: {
        value: '',
        valid: null,
      },
      cf: {
        value: '',
        valid: null,
      }, 
      birthYear: {
        value: '',
        valid: null,
      },         
      password: {
        value: '',
        valid: null,
      },      
      password2: {
        value: '',
        valid: null,
      },   
      phone: {
        value: '',
        valid: null,
      },   
      email: {
        value: '',
        valid: null,
      },   
      condition: {
        value: false,
        valid: null,
      },
      condition2: {
        value: false,
        valid: null,
      },        
    }
  },

  // Get data
  getters: {
    getName(state) {
      return state.data.name;
    },
    getSurname(state) {
      return state.data.surname;
    },
    getCf(state) {
      return state.data.cf;
    },
    getBirthYear(state) {
      return state.data.birthYear;
    },    
    getBirthYearFromCf(state) {
      if(state.data.cf.valid) {
        return state.data.cf.value.substring(6, 8);      
      }
      else {
        return null;
      }
    },
    getPassword(state) {
      return state.data.password;
    },
    getPassword2(state) {
      return state.data.password2;
    },    
    getPhone(state) {
      return state.data.phone;
    },    
    getEmail(state) {
      return state.data.email;
    },        
    getCondition(state) {
      return state.data.condition;
    },
    getCondition2(state) {
      return state.data.condition2;
    },    
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {
      if(payload.data == 'name') {
        state.data.name.value = payload.value;
        state.data.name.valid = payload.valid;
      }       
      else if(payload.data == 'surname') {
        state.data.surname.value = payload.value;
        state.data.surname.valid = payload.valid;        
      }
      else if(payload.data == 'cf') {
        state.data.cf.value = payload.value;
        state.data.cf.valid = payload.valid;
      } 
      else if(payload.data == 'birthYear') {
        state.data.birthYear.value = payload.value;
        state.data.birthYear.valid = payload.valid;
      }       
      else if(payload.data == 'password') {
        state.data.password.value = payload.value;
        state.data.password.valid = payload.valid;        
      }       
      else if(payload.data == 'password2') {
        state.data.password2.value = payload.value;
        state.data.password2.valid = payload.valid;        
      }
      else if(payload.data == 'phone') {
        state.data.phone.value = payload.value;
        state.data.phone.valid = payload.valid;        
      }                       
      else if(payload.data == 'email') {
        state.data.email.value = payload.value;
        state.data.email.valid = payload.valid;        
      }    
      else if(payload.data == 'condition') {
        state.data.condition.value = payload.value;
        state.data.condition.valid = payload.valid;        
      }
      else if(payload.data == 'condition2') {
        state.data.condition2.value = payload.value;
        state.data.condition2.valid = payload.valid;        
      }
    },

    init(state) {
      state.data.name.value = '';
      state.data.name.valid = null;

      state.data.surname.value = '';
      state.data.surname.valid = null;        

      state.data.cf.value = '';
      state.data.cf.valid = null;        

      state.data.birthYear.value = '';
      state.data.birthYear.valid = null,

      state.data.password.value = '';
      state.data.password.valid = null;        

      state.data.password2.value = '';
      state.data.password2.valid = null;        

      state.data.phone.value = '';
      state.data.phone.valid = null;        

      state.data.email.value = '';
      state.data.email.valid = null;        

      state.data.condition.value = false;
      state.data.condition.valid = null;

      state.data.condition2.value = false;
      state.data.condition2.valid = null;      
    },    
  },

  // Async methods
  actions: {
    async registration(context) {

      // Group id from settings store
      const groupId = context.rootGetters['settings/get'].id;   

      const payload = {
        fiscal_code: context.state.data.cf.value,
        birth_year: context.state.data.birthYear.value,
        name: context.state.data.name.value,
        surname: context.state.data.surname.value,
        password: context.state.data.password.value,
        phone: context.state.data.phone.value,
        email: context.state.data.email.value,
        condition: context.state.data.condition.value,
        condition2: context.state.data.condition2.value,
        group_id: groupId
      }

      try {
        const response = await apiAxios.post(process.env.VUE_APP_SSO_ENDPOINT+'sign-up', payload);

        return {success: true, id: response.data.id};
      } catch(e) {
        let msg;
        try {
          msg = e.response.data.message;
        } catch(e) {
          msg = 'Si è verificato un errore imprevisto, riprovare più tardi';
        }

        return {success: false, errorMessage: msg};
      }
    }    
  }
};