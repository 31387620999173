<template>
    <i :class="[
      icon,
      size,
      color
    ]"></i>
</template>

<script>
export default {
  name: 'DlIcon',
  props: {
    icon: {
      default: null,
      type: String,
    },
    size: {
      default: 'fs-5',
      type: String,
    },
    color: {
      default: null,
      type: String,
    }
  },
};
</script>