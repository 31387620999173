import apiAxios from '../../libs/apiAxios';

export default {
  namespaced: true,

  // Data
  state: {
    doctorMessage: null,
  },

  // Get data
  getters: {
    getDoctorMessage(state) {
      return state.doctorMessage;
    },
  },

  // Change data synchronously
  mutations: {
    setDoctorMessage(state, payload) {
      state.doctorMessage = payload;
    },
  },

  // Async methods
  actions: {
    async retrieveDoctorMessage(context, payload) {
      try {
        const response = await apiAxios.get('doctorMessages/' + payload.doctor_id + '/' + payload.exam_id);
        context.commit('setDoctorMessage', response.data.message);
      } catch (e) {
        context.commit('setDoctorMessage', null);
      }
    },
    async retrieveAndReturnDoctorMessage(context, payload) {
      try {
        const response = await apiAxios.get('doctorMessages/' + payload.doctor_id + '/' + payload.exam_id);
        return response.data.message;
      } catch (e) {
        return null;
      }
    },
  },
};
