import apiAxios from '../../libs/apiAxios'

export default { 
  namespaced: true,

  // Data
  state: {
    data: []
  },

  // Get data
  getters: {
    get(state) {
      return state.data;
    }
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {
        state.data = payload;
    },

    init(state) {
      state.data = [];
    }   
  },

  // Async methods
  actions: {
    async retrieve(context, payload) {
      let queryString = '';

      // Organization id from app store
      const orgId = context.rootGetters['app/get'].orgId;
      if (orgId !== null) {
        queryString = '?orgId=' + orgId;
      }

      if (payload.insurance !== undefined && payload.insurance !== null) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'insuranceId=' + payload.insurance;
      } 

      if (payload.exam !== undefined && payload.exam !== null && payload.exam !== 0) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'examId=' + payload.exam;
      }          

      if (payload.location !== undefined && payload.location !== null && payload.location !== 0) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'locationId=' + payload.location;
      }      

      const response = await apiAxios.get('/doctors'+queryString);

      let allDoctors = [{
        label: 'Tutti i medici',
        id: 0
      }];
      Array.prototype.push.apply(allDoctors, response.data);

      context.commit('set', allDoctors);
    }    
  }
};