import { createStore } from 'vuex';

import settings from './settings/settings';
import booking from './booking/booking';
import insurances from './insurances/insurances';
import exams from './exams/exams';
import doctors from './doctors/doctors';
import prescription from './prescription/prescription';
import app from './app/app';
import auth from './auth/auth';
import registration from './registration/registration';
import locations from './locations/locations';
import availabilities from './availabilities/availabilities';
import doctorsExam from './doctorsExam/doctorsExam';
import reservations from './reservations/reservations';
import organizations from './organizations/organizations';
import place from './place/place';
import recall from './recall/recall';
import examsRecall from '@/store/examsRecall/examsRecall';
import messages from '@/store/messages/messages';

export default createStore({
  // Data
  state: {
  },

  // Get data
  getters: {
  },

  // Change data synchronously
  mutations: {
  },

  // Async methods
  actions: {
  },

  // External store modules
  modules: {
    settings,
    booking,
    insurances,
    exams,
    doctors,
    prescription,
    app,
    auth,
    registration,
    locations,
    availabilities,
    doctorsExam,
    reservations,
    organizations,
    place,
    recall,
    examsRecall,
    messages,
  },
});
