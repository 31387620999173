<template>
    <router-view />
</template>

<script>
import { toRaw } from 'vue';
import { StorageSerializers, useFavicon, useStorage, useTitle } from '@vueuse/core';

export default {
  name: 'App',
  async mounted() {
    const objectPage = useStorage('page', null,
      undefined, { serializer: StorageSerializers.object });

    useTitle(objectPage.value.title);
    useFavicon(objectPage.value.favicon);
    this.$store.commit('auth/autoLogin');
    this.$store.commit('auth/spidRedirectLogin');
    this.$store.commit('app/autoSelectOrg');
    this.$store.dispatch('booking/restoreStateFromLocalStorage');
    this.$store.dispatch('recall/restoreStateFromLocalStorage');

    if(Object.keys(toRaw(this.$store.getters['settings/get'])).length === 0) {
      try {
        await this.$store.dispatch('settings/retrieve');
        await this.$store.dispatch('organizations/retrieve');
      } catch(e) {
        this.$store.commit('app/setLoading', true);
        this.$store.commit('app/setLoadingMessage', 'Si è verificato un errore imprevisto, provare a ricaricare la pagina');
        this.$store.commit('app/setLoadingError', true);
      }
    }
  }
};
</script>
