import apiAxios from '../../libs/apiAxios'

export default {
  namespaced: true,

  // Data
  state: {
    data: {
      ssn: {},
      sol: {},
      age: {}
    },

    error: {
      value: false,
      message: null
    },
  },

  // Get data
  getters: {
    get(state) {
      return state.data;
    },

    getError(state) {
      return state.error;
    },

    getLastAvailability(state) {
      return {
                ssn: state.data.ssn.availabilities[state.data.ssn.availabilities.length - 1],
                sol: state.data.sol.availabilities[state.data.sol.availabilities.length - 1],
                age: state.data.age.availabilities[state.data.age.availabilities.length - 1]
              };
    }
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {
        state.data = payload;
    },

    setError(state, payload) {
      state.error.value = payload.value;
      state.error.message = payload.message;
  },

    init(state) {
      state.data.ssn = {};
      state.data.sol = {};
      state.data.age = {};

      state.error.value = false;
      state.error.message = null;
    },

    merge(state, payload) {
      if(payload.ssn.availabilities.length > 0) {
        Array.prototype.push.apply(state.data.ssn.availabilities, payload.ssn.availabilities);
        state.data.ssn.minPrice = Math.min(state.data.ssn.minPrice, payload.ssn.minPrice);
      }

      if(payload.sol.availabilities.length > 0) {
        Array.prototype.push.apply(state.data.sol.availabilities, payload.sol.availabilities);
        state.data.sol.minPrice = Math.min(state.data.sol.minPrice, payload.sol.minPrice);
      }

      if(payload.age.availabilities.length > 0) {
        Array.prototype.push.apply(state.data.age.availabilities, payload.age.availabilities);
        state.data.age.minPrice = Math.min(state.data.age.minPrice, payload.age.minPrice);
      }

    },

    setType(state, payload) {
      state.data[payload.type] = payload.data;
    },
  },

  // Async methods
  actions: {
    async retrieve(context, payload) {
      try {
        context.commit('setError', {value: false, message: null});

        let queryString = '';

        // Organization id from app store
        const orgId = context.rootGetters['app/get'].orgId;
        if (orgId !== null) {
          queryString = '?orgId=' + orgId;
        }

        if (payload.doctor !== undefined && payload.doctor !== null && payload.doctor !== 0) {
          queryString = (queryString === '' ? '?' : queryString+'&')  + 'doctorId=' + payload.doctor;
        }

        if (payload.insurance !== undefined && payload.insurance !== null) {
          queryString = (queryString === '' ? '?' : queryString+'&')  + 'insuranceId=' + payload.insurance;
        }

        if (payload.startDate !== undefined && payload.startDate !== null) {
          queryString = (queryString === '' ? '?' : queryString+'&')  + 'startDate=' + payload.startDate;
        }

        if (payload.type !== undefined && payload.type !== null) {
          queryString = (queryString === '' ? '?' : queryString+'&')  + 'type=' + payload.type;
        }

        let response;

        if (payload.startDate !== undefined && payload.startDate !== null) {
          response = await apiAxios.get('availabilities/'+payload.exam+'/'+payload.location+queryString, {});
          context.commit('merge', response.data);
        }
        else {
          response = await apiAxios.get('availabilities/'+payload.exam+'/'+payload.location+queryString, {});
          if (payload.type !== undefined && payload.type !== null) {
            context.commit('setType', {type: payload.type, data: response.data[payload.type]});
          }
          else {
            context.commit('set', response.data);
          }
        }
      } catch(e) {
        context.commit('setError', {value: true, message: e.response.data.message});
      }
    },

    async retrieveRecall(context, payload) {
      try {
        context.commit('setError', { value: false, message: null });

        let queryString = '';

        // Organization id from app store
        const orgId = context.rootGetters['app/get'].orgId;
        if (orgId !== null) {
          queryString = '?orgId=' + orgId;
        }

        if (payload.startDate !== undefined && payload.startDate !== null) {
          queryString = (queryString === '' ? '?' : queryString+'&') + 'startDate=' + payload.startDate;
        }

        const priorityClasses = context.rootGetters['recall/getPrescriptionsPriorities'];

        let response;

        response = await apiAxios.get('availabilities-callback/' + priorityClasses + queryString, {});

        if (payload.startDate !== undefined && payload.startDate !== null) {
          context.commit('merge', response.data);
        }
        else {
          context.commit('set', response.data);
        }
      } catch (e) {
        context.commit('setError', { value: true, message: e.response.data.message });
      }
    },
  }
};
