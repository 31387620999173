import apiAxios from '../../libs/apiAxios';

export default {
  namespaced: true,

  // Data
  state: {
    data: [],
    totalLocationsNumber: 0,
  },

  // Get data
  getters: {
    get(state) {
      return state.data;
    },

    getTotalLocationsNumber(state) {
      return state.totalLocationsNumber;
    },
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {
      state.data = payload;
    },

    setTotalLocationsNumber(state, payload) {
      state.totalLocationsNumber = payload;
    },

    init(state) {
      state.data = [];
      state.totalLocationsNumber = 0;
    },
  },

  // Async methods
  actions: {
    async retrieve(context, payload) {
      let queryString = '';

      // Organization id from app store
      const orgId = context.rootGetters['app/get'].orgId;
      if (orgId !== null) {
        queryString = '?orgId=' + orgId;
      }

      if (payload.exam !== undefined && payload.exam !== null && payload.exam !== 0) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'examId=' + payload.exam;
      }

      if (payload.doctor !== undefined && payload.doctor !== null && payload.doctor !== 0) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'doctorId=' + payload.doctor;
      }

      const response = await apiAxios.get('/locations'+queryString);

      context.commit('set', response.data);
    },

    async retrieveAll(context) {
      let queryString = '';

      // Organization id from app store
      const orgId = context.rootGetters['app/get'].orgId;
      if (orgId !== null) {
        queryString = '?orgId=' + orgId;
      }

      const response = await apiAxios.get('/locations'+queryString);

      context.commit('setTotalLocationsNumber', response.data.length);
    },
  },
};
