import axios from 'axios'
import store from '@/store'
import router from "@/router"
import VueJwtDecode from 'vue-jwt-decode'

const apiAxios = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT
})

// Add a request interceptor
apiAxios.interceptors.request.use(function (config) {
  // Do something before request is sent  
  store.commit('app/setLoading', true);

  const tokens = store.getters['auth/loggedUserTokens'];
  /*
  let isTokenValid = false;
  if (tokens.access_token !== null) {  
    try {
      // Subtracts 30 seconds to have more leeway
      const tokenExp = VueJwtDecode.decode(tokens.access_token).exp - 30;
      const now = Math.floor(new Date().getTime() / 1000);
      
      if(tokenExp > now) {
        isTokenValid = true;
      }
      else {
        isTokenValid = false;
      }
    }
    catch(e) {
      isTokenValid = false;
    }
  }
  */
  // Temporarily always true
  let isTokenValid = true;
  
  if(isTokenValid && config.url !== process.env.VUE_APP_SSO_ENDPOINT+'refresh-token') {
    config.headers.Authorization = `Bearer ${tokens.access_token}`;    
  }

  return config;
}, function (error) {
  // Do something with request error  
  store.commit('app/setLoadingMessage', 'Si è verificato un errore imprevisto, provare a ricaricare la pagina');
  store.commit('app/setLoadingError', true);

  return Promise.reject(error);
});

// Add a response interceptor
apiAxios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  store.commit('app/setLoading', false);

  return response;
}, async function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  
  const originalRequest = error.config;

  // Prevent refreshToken infinite loops
  if (error.response.status === 401 && originalRequest.url === process.env.VUE_APP_SSO_ENDPOINT+'refresh-token') {
    store.commit('auth/logout');
    router.push('/login');
    return Promise.reject(error);
  }
  
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const tokens = store.getters['auth/loggedUserTokens'];
    const refresh = await store.dispatch('auth/refreshToken', tokens.refresh_token);
    if(refresh.success) {
      return apiAxios(originalRequest);
    }
  }

  store.commit('app/setLoading', false);

  return Promise.reject(error);
});

export default apiAxios