import apiAxios from '../../libs/apiAxios'

export default {
  namespaced: true,

  // Data
  state: {
    data: [],
  },

  // Get data
  getters: {
    get(state) {
      return state.data;
    },
    getExamById: (state) => (id) => {
      if (state.data.length === 0) {
        return {};
      }
      id = typeof id === 'string' ? parseInt(id) : id;
      return state.data.find(exam => exam.id === id);
    },
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {
      state.data = payload;
    },

    init(state) {
      state.data = [];
    },
  },

  // Async methods
  actions: {
    async retrieve(context, payload) {
      let queryString = '';

      // Organization id from app store
      const orgId = context.rootGetters['app/get'].orgId;
      if (orgId !== null) {
        queryString = '?orgId=' + orgId;
      }

      const response = await apiAxios.get('/callback-exams'+queryString);

      const data = response.data;

      context.commit('set', data);
    },
  },
};
