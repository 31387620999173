//import router from '../../router/index'
import cookies from 'vue-cookies'

export default {
  namespaced: true,

  // Data
  state: {
    data: {
      loading: false,
      loadingMessage: 'Caricamento...',
      loadingError: false,
      orgId: null,

      bookingTypes: {
                'sol': 'Visita privata',
                'ssn': 'Servizio Sanitario Nazionale',
                'age': 'Privato agevolato',
              }
    }
  },

  // Get data
  getters: {
    get(state) {
      return state.data;
    },

    getBookingType(state) {
      return state.data.bookingTypes;
    }
  },

  // Change data synchronously
  mutations: {
    setLoading(state, payload) {
      state.data.loading = payload;
    },

    setLoadingMessage(state, payload) {
      state.data.loadingMessage = payload;
    },

    setLoadingError(state, payload) {
      state.data.loadingError = payload;
    },

    setOrgId(state, payload) {
      state.data.orgId = payload;
      // Save in cookie only if not null
      if (payload) {
        cookies.set('orgId', payload, 0); // 0 means till the end of session
      }
    },

    // Auto select org from cookie
    autoSelectOrg(state) {
      let orgId;
      if (cookies.isKey('orgId')) {
        orgId = cookies.get('orgId');
      } else {
        orgId = null;
      }

      if (orgId) {
        state.data.orgId = orgId;
      }
    }
  },

  // Async methods
  actions: {
    /*async retrieveOrgId(context) {
      //let orgId = 987;
      let orgId = null;
      console.log(['router', router.currentRoute._value.query.orgId]);
      context.commit('setOrgId', orgId);
    }*/
  }
};
