<template>
    <DlLoading v-if="isLoading" />

    <header class="o-dl-header">
      <div :class="[
        'container d-flex align-items-center  py-3',
        showLogo ? 'justify-content-between' : 'justify-content-end'
      ] ">
        <DlLogo v-if="showLogo" :logo="logo" />

        <DlButton
          v-if="!isLogged && !isMaintenanceMode"
          :class="'text-primary'"
          :clean="true"
          href="/login"
          icon="bi bi-person"
          label="Login"
          subLabel="Accedi al tuo account"
          type="link"
        />

        <DlButton
          v-if="isLogged && !isMaintenanceMode"
          :class="'text-primary'"
          :clean="true"
          icon="bi bi-list"
          :label="loggedUser"
          subLabel="Area riservata"
          data-bs-toggle="offcanvas"
          data-bs-target="#menu"
          aria-controls="menu"
        />         
      </div>

      <div class="offcanvas offcanvas-end" tabindex="-1" id="menu" aria-labelledby="menuLabel">
        <div class="offcanvas-header">
          <h5 id="menuLabel" class="text-primary"><strong>Area riservata</strong></h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" id="closebtn"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item border-bottom py-2">
              <a class="nav-link text-primary" href="#" @click.prevent="go('/prenotazioni')">Le mie prenotazioni</a>
            </li>
            <li class="nav-item border-bottom py-2">
              <a class="nav-link text-primary" href="#" @click.prevent="go('/profilo')">Il mio profilo</a>
            </li>
            <li class="nav-item border-bottom py-2">
              <a class="nav-link text-danger" href="#" @click.prevent="logout">Logout</a>
            </li>         
          </ul>
        </div>
      </div>         
    </header> 

</template>

<script>
import DlButton from '@/components/atoms/dl-button/dl-button.vue';
import DlLogo from '@/components/atoms/dl-logo/dl-logo.vue';
import DlLoading from '@/components/atoms/dl-loading/dl-loading.vue';

export default {
  name: 'DlHeader',
  components: {
    DlButton,
    DlLogo,
    DlLoading,
  },
  props: {
    showLogo: {
        default: true,
        type: Boolean
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['app/get'].loading
    },
    isLogged() {
      return this.$store.getters['auth/isLogged']
    },
    loggedUser() {
      return this.$store.getters['auth/loggedUser']
    },
    logo() {
      return this.$store.getters['settings/get'].logo
    },
    isMaintenanceMode() {
      return this.$store.getters['settings/isMaintenanceMode'];
    },
    isDevMode() {
      return this.$store.getters['settings/isDevMode'];
    }    
  },
  methods: {
    logout() {
      this.$store.commit('auth/logout');
    },
    go(to) {
      document.getElementById('closebtn').click(); // Force closing the menu
      this.$router.push(to);
    }
  }
};
</script>

<style lang="scss">
  @import 'dl-header.scss';
</style>