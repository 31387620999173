import apiAxios from '../../libs/apiAxios'

export default {
  namespaced: true,

  // Data
  state: {
    data: []
  },

  // Get data
  getters: {
    get(state) {
      return state.data;
    },
    getExamById: (state) => (id) => {
      if (state.data.length === 0) {
        return {};
      }
      id = typeof id === 'string' ? parseInt(id) : id;
      return state.data.find(exam => exam.id === id);
    },
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {
        state.data = payload;
    },

    init(state) {
      state.data = [];
    }
  },

  // Async methods
  actions: {
    async retrieve(context, payload) {
      let queryString = '';

      // Organization id from app store
      const orgId = context.rootGetters['app/get'].orgId;
      if (orgId !== null) {
        queryString = '?orgId=' + orgId;
      }

      if (payload.insurance !== undefined) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'insuranceId=' + payload.insurance;
      }

      if (payload.doctor !== undefined) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'doctorId=' + payload.doctor;
      }

      if (payload.cup_telefonico !== undefined) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'cup_telefonico=' + payload.cup_telefonico;
      }

      const response = await apiAxios.get('/exams'+queryString);

      const data = response.data;
      // Remap values
      /*
      const data = response.data.map(item => {
        const res = {};
        res.id =  item.id;
        res.label =  item.label + (item.cur != '' && item.cur !== null ? ' (' + item.cur + ')': '');
        res.bookable_online = item.bookable_online;
        res.patient_message = item.patient_message;
        return res;
      });
      */

      context.commit('set', data);
    }
  }
};
