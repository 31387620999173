<template>
  <div class="o-banner mt-5">
      <div class="col-12">
        <span v-html="getIntroMessage" class="formatted-p"></span>
      </div>
    </div>
</template>

<script>
import DlImage from '@/components/atoms/dl-image/dl-image.vue';

export default {
  name: 'DlIntro',
  components: {
    DlImage,
  },
  computed: {
    getIntroMessage() {
      const msg = '<h3>Supporto specialistico alla prenotazione</h3><br><p>Per difficoltà nell\'individuare la prestazione che desideri prenotare online o la convenzione a cui hai diritto, puoi richiedere l\'assistenza telefonica al numero <strong>02.000 00 000</strong><br><br>Da Lunedì a Venerdì, dalle 8:00 alle 18:00, Sabato dalle 8:00 alle 12:00.</p>';
      return this.$store.getters['settings/get'].homepage_text ?? msg;
    }
  }
};
</script>

<style lang="scss">
  @import 'dl-intro.scss';
</style>
